import React, { useState } from "react";
import "./HeaderSimple.css";
import { NavigatorWrapper } from "./NavigatorWrapper";
import Constants from "utilities/js/Constants";
import { isMobile } from "utilities/js/DeviceLibrary";


import { navigateToLandingPage } from "utilities/js/NavigationHelper";
import { useNavigate } from "react-router-dom";
import FullPagePanel from "./FullPagePanel";
import HeaderButton from "./HeaderButton";
import { navigateToPage } from "utilities/js/NavigationHelper";
import Logo from "./Logo";
import HeaderButtonDropdown from "./HeaderButtonDropdown";

// import { HistoryNavigator } from "./HistoryNavigator";

const LogoIcon = Constants.ICON_LOGO;
const myOptions = [
    {label: 'Window Cleaning', url: "/services/window-cleaning"},
    {label: 'Pressure Washing', url: "/services/pressure-washing"},
    {label: 'High Dusting', url: "/services/high-dusting"},
    {label: 'Carpet Cleaning', url: "/services/carpet-cleaning"},
    {label: 'Gutter Cleaning', url: "/services/gutter-cleaning"},
    {label: 'Snow Plowing', url: "/services/snow-plowing"},
    {label: 'Christmas Light & Decor Install', url: "/services/decor-install"}
  ];

let HeaderSimple = () => {

    const navigate = useNavigate();
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    function navigateToLandingPage(){
        // this.props.history.push("/");
        navigateToPage({url: '/'});
    }

    const navigateToRequestQuote = () => {
        // Handle option click (e.g., call a function, navigate, etc.)
        navigateToPage({url: '/request-quote'});
    };

    function getMobileHeader(){
        return (
            <div className="mobile-header-wrapper">
                <div class="header-simple">
                    
                    <FullPagePanel shouldDisplay={showMobileMenu} closePanel={() => {setShowMobileMenu(false)}}>
                        <div className="mobile-header-panel">
                            {getHeaderButtons()}
                        </div>
                    </FullPagePanel>

                    <div className="phone-icon-container">
                        <a href={"tel:" + Constants.PHONE_NUMBER}><img className="phone-icon" src={Constants.ICON_PHONE}></img></a>
                    </div>
                    <div onClick={navigateToLandingPage}>
                        <Logo variant="header"></Logo>
                    </div>

                    <div class="hamburger-nav" onClick={() => {setShowMobileMenu(true)}}>
                        &equiv;
                    </div>
                </div>
                <div className="sub-header-info">
                    Call Today <div className="phone-number">{Constants.PHONE_NUMBER}</div>
                </div>
            </div>
        )
    }

    function getDesktopHeader(){
        return (
            <div class="header-simple">
                <div className="header-simple-restrict-width">
                    <div className="banner-title-text home-button" onClick={navigateToLandingPage}>
                        <Logo variant="header"></Logo>
                    </div>
                    <div className="header-action-section">
                        <div className="header-action-top">
                        <div className="header-action"><div className="header-phone-prompt">Call Today </div><div className="header-phone">{Constants.PHONE_NUMBER}</div></div>
                            <div className="header-action">
                                <div className="request-quote-button" onClick={navigateToRequestQuote}>Get Quote</div>
                            </div>
                        </div>

                    <div className="header-buttons">
                        {getHeaderButtons()}
                    </div>
                    </div>
                </div>
        </div>);
    }


    function getHeaderButtons(){
        return [
            <HeaderButtonDropdown options={myOptions} label="Services" url="/services" />,
            // <HeaderButton key="Services" label="Services" url="/services"></HeaderButton>,
            <HeaderButton key="Our Team" label="Our Team" url="/our-team"></HeaderButton>,
            <HeaderButton key="Job Application" label="Job Application" url="/job-application"></HeaderButton>,
            <HeaderButton key="Contact" label="Contact" url="/contact"></HeaderButton>
        ];
    }

    function displayHeader(){
        if(isMobile()){
            return getMobileHeader();
        }else{
            return getDesktopHeader();
        }
    }
    
    
    return (<>
        
        {displayHeader()}
            {/* <div className="brackset-header-simple-logo" onClick={navigateToLandingPage}>
                <div className="brackset-header-simple-logo-container">
                    <img className="brackset-header-simple-logo-icon" src={LogoIcon} alt="logo-icon"></img>
                    <div className="brackset-header-simple-logo-name">Residential Resources</div>
                </div>
                <div className="brackset-header-simple-logo-tagline">The worldwide leader in bracket generation</div>

            </div> */}
        </>)
    
}

export default NavigatorWrapper(HeaderSimple);
