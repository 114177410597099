import React, { useState } from "react";
// import "./GutterCleaning.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const GutterCleaning = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_GUTTER} isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Gutter Cleaning
                            </div>
                            <div className="full-image-text">
                                What we offer
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                    <div className="standalone-text no-padding-top">
                            <p className="italics-text">
                                Ohio's premier residential gutter cleaning professionals. 
                            </p>
                            No more clogged downspouts or climbing ladders. Allow Residential Resources of Ohio to keep your gutters leaf and debris free. Below are a few reasons why gutter cleaning is essential to residential maintenance:

                            <p>
                            <b>1. Prevent Water Damage:</b> Clogged gutters can cause water to overflow and seep into your home's foundation, walls, and roof. Over time, this can lead to costly water damage repairs, including mold growth, rotting wood, and damaged insulation.
                            </p>

                            <p>
                            <b>2. Avoid Pest Infestation:</b> Debris like leaves, twigs, and dirt can attract pests such as insects, birds, and rodents. These pests may nest in your gutters and make their way into your home.
                            </p>

                            <p>
                            <b>3. Preserve Roof and Gutters:</b> When gutters are clogged, the water cannot flow freely, and this can lead to sagging gutters or damage to your roof's shingles and fascia. Regular cleaning helps prolong the life of your roof and gutters.
                            </p>
                            Rest assured, when you select Residential Resources as your service provider, you are choosing a motivated, considerate and professionally trained staff that is 100% committed to meeting and exceeding your expectations.  Our growth is linked to your satisfaction.  Call today to schedule your free estimate!     

                        </div>

                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default GutterCleaning;
