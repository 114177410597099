import React, { useState } from "react";
import "./HeaderSimple.css";
import { NavigatorWrapper } from "./NavigatorWrapper";
import Constants from "utilities/js/Constants";
import { isMobile } from "utilities/js/DeviceLibrary";


import { navigateToLandingPage } from "utilities/js/NavigationHelper";
import { useNavigate } from "react-router-dom";
import FullPagePanel from "./FullPagePanel";
import HeaderButton from "./HeaderButton";
import { navigateToPage } from "utilities/js/NavigationHelper";
import Logo from "./Logo";

// import { HistoryNavigator } from "./HistoryNavigator";

const LogoIcon = Constants.ICON_LOGO;

let Header = () => {

    const navigate = useNavigate();
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    function navigateToLandingPage(){
        // this.props.history.push("/");
        navigateToPage({routerNav: navigate, url: '/'});
    }

    function getMobileHeader(){
        return (
        <div class="header-simple">
            
            <FullPagePanel shouldDisplay={showMobileMenu} closePanel={() => {setShowMobileMenu(false)}}>
                <div className="mobile-header-panel">
                    {getHeaderButtons()}
                </div>
            </FullPagePanel>

			<div class="banner-title-text home-button" onClick={navigateToLandingPage}>
				<img src={Constants.ICON_LOGO}></img>
			</div>

			<div class="hamburger-nav" onClick={() => {setShowMobileMenu(true)}}>
				&equiv;
			</div>
		</div>
        )
    }

    function getDesktopHeader(){
        return (
            <div class="header-simple">
                <div className="banner-title-text home-button" onClick={navigateToLandingPage}>
                    <Logo variant="header"></Logo>
                </div>
                <div className="header-activity">
                    <div className="header-activity-prompt">

                    </div>
                    <div className="header-buttons">
                        {getHeaderButtons()}
                    </div>
                </div>
        </div>);
    }


    function getHeaderButtons(){
        return [
            <HeaderButton key="Services" label="Services" url="/services"></HeaderButton>,
            <HeaderButton key="Our Team" label="Our Team" url="/our-team"></HeaderButton>,
            <HeaderButton key="Job Application" label="Job Application" url="/job-application"></HeaderButton>,
            <HeaderButton key="Contact" label="Contact" url="/contact"></HeaderButton>
        ];
    }

    function displayHeader(){
        if(isMobile()){
            return getMobileHeader();
        }else{
            return getDesktopHeader();
        }
    }
    
    
    return (<>
        
        {displayHeader()}
            {/* <div className="brackset-header-simple-logo" onClick={navigateToLandingPage}>
                <div className="brackset-header-simple-logo-container">
                    <img className="brackset-header-simple-logo-icon" src={LogoIcon} alt="logo-icon"></img>
                    <div className="brackset-header-simple-logo-name">Residential Resources</div>
                </div>
                <div className="brackset-header-simple-logo-tagline">The worldwide leader in bracket generation</div>

            </div> */}
        </>)
    
}

export default NavigatorWrapper(Header);
