import React from "react";
import "./ErrorPage.css";
import getURLParam from "utilities/js/ParameterRetrieval";
import Header from "components/reusable/Header";
import Footer from "components/reusable/Footer";


class ErrorPage extends React.Component {
    
    constructor(props){
        super(props);
        
        let errorType = getURLParam('errorType')
        this.state = {
            errorType: errorType
        };
        
        this.displayContent = this.displayContent.bind(this);
        
    }
    
    
    get errorMessage(){
        
        if(this.state.errorType === 'bracketError'){
            return 'There was an issue retrieving the requested bracket.  The bracket may not exist or may have had its format changed';
        }else{
            return 'We\'re sorry, but we cannot process your request';
        }
    }
    
    displayContent(){
        return (<>
            <div className="simple-form-result-container">
                <div className="simple-form-header">Something went wrong...</div>
                <div className="simple-form-error-result">
                    {this.errorMessage}
                    <div className="simple-form-redirect">Click <a href="/">here</a> to navigate to the home page</div>
                </div>
            </div>
        </>)
    }
            
    render(){ 
        
        return (
            <>
            <div className="brackset-container">
                
                <Header hideSearch="true"></Header>
                <div className="simple-form">
                    {this.displayContent()}
                </div>
                <Footer></Footer>

            </div>
            </>
        )
            
    }
}

export default ErrorPage;
    