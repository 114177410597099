import React, { useState } from "react";
import "./LandingPage.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

import PictureInfo from "components/reusable/PictureInfo";
import { navigateToPage } from "utilities/js/NavigationHelper";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;
const pictureInfo = [
    {
        name: 'Who are we?',
        info: 'GMat Fitness, owned and operated by Grace Matonis, opened in 2024. It is a one-on-one and small group personal training gym. No crowds or waiting for equipment. The quiet atmosphere of this gym helps clients feel comfortable without being nervous of judgment from others.',
        image: Constants.IMAGE_WINDOW_WASHING_1,
        variant: null
    },
    {
        name: 'Our Values',
        info: 'The goal of any personal trainer is to have their client become confident in themselves and working out in the gym alone. Grace helps her clients build this confidence through strength training. It is important to note that GMat Fitness is not similar to a gym chain. Workouts are personalized to your specific needs and there is no public access.',
        image: Constants.IMAGE_WINDOW_WASHING_2,
        variant: 'reverse'
    },
    {
        name: 'Our Space',
        info: 'Our gym is located in a warehouse giving an “old school” feel to the environment. Equipment is kept very clean and disinfected after every use.',
        image: Constants.IMAGE_POWER_WASHING,
        variant: 'border'
    },
    {
        name: 'Our mascot',
        info: 'Most importantly, the gym\'s mascot, Thor, loves to greet each client personally. He is a 10-year-old German Shepard who is supportive to all members of this gym.',
        image: Constants.IMAGE_CARPET_CLEANING,
        variant: 'center'
    },
]
const LandingPage = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }

    function getPictureInfoMarkup(){
        
        return pictureInfo.map(info=>{

            return (<PictureInfo name={info.name} info={info.info} image={info.image} variant={info.variant}></PictureInfo>)
        })
    }
    
    const navigateToRequestQuote = () => {
        // Handle option click (e.g., call a function, navigate, etc.)
        navigateToPage({routerNav: navigate, url: '/request-quote'});
    };

    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_RESTIDENTIAL_RESOURCES} isParallax="true">
                        <div className="full-image-content desktop-only">
                            <div className="full-image-text">
                                Residential Resources
                            </div>
                            <div className="full-image-text">
                                Make your home sparkle
                            </div>
                        </div>
                        <div className="full-image-content mobile-only">
                            <div className="full-image-call-message">
                                <div className="mobile-call-prompt">
                                    <div className="request-quote">Call to Schedule Your Service Today</div>
                                    <div>{Constants.PHONE_NUMBER}</div>
                                </div>
                                <div className="request-quote"><div className="request-quote-button" onClick={navigateToRequestQuote}>Request Free Quote</div></div>
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                        <div className="logo-container"><img className="bracket-logo" alt="bracket-logo" src={LogoIcon} /></div>
                        <div className="standalone-text">
                            Residential Resources is Northeast Ohio's one-stop shop for residential maintenance. With expertise in window cleaning, pressure washing, gutter cleaning and more, we hope to bring our aesthetically pleasing and foundation preserving services to every door. Residential Resources is a fully insured, SBE certified and locally owned Ohio company. 
                        </div>
                        {/* <div className="landing-page-masthead">Residential Resources</div>
                        <div className="landing-page-tagline">The worldwide leader in residential resources</div> */}
                        
                        <div className="standalone-text">
                            <div className="highlight-text">Areas we serve</div>
                            <div>We serve customers in the Northeast Ohio area, covering locations between Sandusky and Ashtabula and as far south as to Mansfield</div>
                            <div className="standalone-image-container">
                                <img className="standalone-image" src={Constants.IMAGE_AREA_OF_SERVICE}></img>
                            </div>
                        </div>
                        <div className="google-review-container">Google Reviews Coming Soon</div>
                        <div className="standalone-text">Residential Resources is fully covered with Workers' Compensation and liability insurance for your protection.  If there are any questions regarding the above, please do not hesitate to call.</div>
                    </div>
                    {/* {getPictureInfoMarkup()} */}
                </div>

                <Footer></Footer>
            </div>
        </>
    );

}


export default LandingPage;
