import React from "react";
import "./Logo.css";
import Constants from "utilities/js/Constants";

let Logo = (props) => {
    
    return (<>
        <div className={"header-logo-container " + props.variant}>
            <img className="header-logo" src={Constants.ICON_LOGO}></img>
            {/* <div className="logo-text">
                <div className="logo-text-line">RESIDENTIAL</div>
                <div className="logo-text-line spacing">RESOURCES</div>
            </div> */}
        </div>
           
    </>)
    
}

export default Logo;
