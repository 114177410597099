import Constants from "utilities/js/Constants";
import CookieManager from "utilities/js/localstorage/CookieManager";
// import { isLoggedIn } from "./ActionLibrary";

let maintainHistory = true;

export function navigateToPage(options){
    if(options?.routerNav){
        options.routerNav(options.url)
    }else if(maintainHistory){
        window.location = Constants.HOST_URL + options.url;
    }else{
        window.location.replace(Constants.HOST_URL + options.url);
    }
}
export function navigateToCreate(options){
    if(options?.routerNav){
        options.routerNav('/bracket/' + options.bracketId + '/create')
    }else if(maintainHistory){
        window.location = Constants.HOST_URL + '/bracket/' + options.bracketId + '/create'
    }else{
        window.location.replace(Constants.HOST_URL + '/bracket/' + options.bracketId + '/create');
    }
}

export function navigateToView(options){
    if(options?.routerNav){
        options.routerNav('/bracket/' + options.bracketId + '/view')
    }else if(maintainHistory){
        // window.history.replaceState(null, "New Page Title", Constants.HOST_URL + '/bracket/' + options.bracketId + '/view')
        window.location = Constants.HOST_URL + '/bracket/' + options.bracketId + '/view'
    }else{
        window.location.replace(Constants.HOST_URL + '/bracket/' + options.bracketId + '/view');
    }
}

export function navigateToNew(options){
    if(options?.routerNav){
        options.routerNav('/bracket/create')
    }else if(maintainHistory){
        window.location = Constants.HOST_URL + '/bracket/create';
    }else{
        window.location.replace(Constants.HOST_URL + '/bracket/create');
    }
}

export function navigateToLandingPage(options){    

    let params = '';
    let defaultScreen = options?.defaultScreen;
    if(!defaultScreen){
        defaultScreen = Constants.SCREEN_NAME_HOME;
    }
    params = '?screen=' + defaultScreen;

    let cookieManager = new CookieManager();
    
    if(options?.routerNav && window.location.pathname !== '/' ){
        options.routerNav('/' + params)
    }else if(window.location.pathname === '/'/* && !!isLoggedIn()*/){
        updateURLWithoutNavigation(defaultScreen);
    }else if(options?.maintainHistory){        
        // window.history.replaceState(null, "New Page Title", Constants.HOST_URL + params)
        window.location = Constants.HOST_URL + params;
    }else{
        window.location.replace(Constants.HOST_URL + params);
    }
}

export function navigateToForgotPasswordPage(options){
    if(options?.routerNav){
        options.routerNav('/forgot-password')
    }else if(maintainHistory){
        window.location = Constants.HOST_URL + '/forgot-password';
    }else{
        window.location.replace(Constants.HOST_URL + '/forgot-password');
    }
}

export function navigateToAccountPage(options){
    let destination = Constants.HOST_URL + '/account/' + options.accountId;

    if(destination != window.location){
        if(options?.routerNav){
            options.routerNav('/account/' + options.accountId)
        }else if(maintainHistory){
            window.location = destination;
        }else{
            window.location.replace(destination);
        }
    }
}

export function navigateToContactUsPage(options){
    if(options?.routerNav){
        options.routerNav('/contact')
    }else  if(maintainHistory){
        window.location = Constants.HOST_URL + '/contact';
    }else{
        window.location.replace(Constants.HOST_URL + '/contact');
    }
}



export function navigateToErrorPage(options){
    if(options?.routerNav){
        options.routerNav('/error?errorType=' + options.errorType)
    }else if(maintainHistory){
        window.location = Constants.HOST_URL + '/error?errorType=' + options.errorType;
    }else{
        window.location.replace(Constants.HOST_URL + '/error?errorType=' + options.errorType);
    }
}

export function updateURLWithoutNavigation(defaultScreen){

    window.history.pushState("Home Page", defaultScreen, "/");
    document.title = "Brackset Home";

    
    const nextURL = Constants.HOST_URL + '?screen=' + defaultScreen;
    const nextTitle = 'Brackset';
    const nextState = { additionalInformation: 'Updated the URL with JS' };

    // This will create a new entry in the browser's history, without reloading
    window.history.pushState(nextState, nextTitle, nextURL);
}