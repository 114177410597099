import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { navigateToPage } from 'utilities/js/NavigationHelper';
import "./HeaderButtonDropdown.css";

const HeaderButtonDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleClick = (option) => {
        // Handle option click (e.g., call a function, navigate, etc.)
        console.log(`Clicked on: ${option}`);
        navigateToPage({url: option.url});

        setIsOpen(false); // Close options after click
  };

  const handleMainButtonClick = () => {
    if(props.url){
        navigateToPage({url: props.url});
    }
  }

  return (
    <div 
        className='header-button dropdown-button'
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    >
      <div className="dropdown-button-label" onClick={handleMainButtonClick}>{props.label}<div className='down-arrow'></div></div>
      {isOpen && (
        <ul className='dropdown-list'>
          {props.options.map((option) => (
            <li key={option.label} className='dropdown-list-item' onClick={() => handleClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HeaderButtonDropdown;
