import React, { useState } from "react";
import "./RequestQuote.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const RequestQuote = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_PRESSURE_WASHING_1} isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Request a Quote
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                        <div className="standalone-text centered no-padding-top">
                            <div className="highlight-text">
                                For a free quote today:
                            </div>
                            <div>Call us at {Constants.PHONE_NUMBER}</div>
                            <div>or send us an email with your name, phone number and zip code at</div>
                            <div>{Constants.EMAIL_ADDRESS}</div>
                        </div>
                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default RequestQuote;
