import React, { useState } from "react";
import "./JobApplication.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const JobApplication = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_WINDOW_WASHING_2} isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Job Application
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                        <div className="standalone-text">
                            Residential Resources offers a unique chance to be a part of a multi-faceted and fast-growing company which offers several unique opportunities in the form of different job positions and vertical mobility within our corporation.   We are a team and goal-oriented company which welcomes all, regardless of experience, who are willing to work hard and learn.
                        </div>
                        <div className="standalone-text centered">
                            <div className="highlight-text">
                                Interested in joining our team?
                            </div>
                            <div>Call {Constants.PHONE_NUMBER}</div>
                        </div>
                        <div className="standalone-text">
                            Online application coming soon!
                        </div>
                    </div>


                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default JobApplication;
