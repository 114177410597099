import React from "react";
import "./Footer.css";
import { navigateToContactUsPage } from "utilities/js/NavigationHelper";
import Constants from "utilities/js/Constants";
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "utilities/js/NavigationHelper";


const LogoIcon = Constants.ICON_LOGO_WHITE;
const InstagramLogo = Constants.ICON_INSTAGRAM;
const TwitterLogo = Constants.ICON_TWITTER;
const InstagramLogoBlue = Constants.ICON_INSTAGRAM_BLUE;
const TwitterLogoBlue = Constants.ICON_TWITTER_BLUE;


let Footer = (props) => {

    const navigate = useNavigate();

    const navigateToRequestQuote = () => {
        // Handle option click (e.g., call a function, navigate, etc.)
        navigateToPage({url: '/request-quote'});
    };
    const navigateToJobApplication = () => {
        // Handle option click (e.g., call a function, navigate, etc.)
        navigateToPage({url: '/job-application'});
    };



    function getFullFooter() {
        return (
        <div className="footer">
            <div className="request-quote-container">
                <div className="header-action"><div className="header-phone-prompt">Call Today </div><div className="header-phone">{Constants.PHONE_NUMBER}</div></div>
                <div className="header-action">
                    <div className="request-quote-button" onClick={navigateToRequestQuote}>Get Quote</div>
                </div>
            </div>

                <div className="footer-info">

                    <div className="footer-logo">
                        <div className="footer-logo-container">
                            <img className="footer-logo-icon" src={LogoIcon} alt="logo-icon"></img>
                        </div>
                    </div>
                    
                    <div className="contact-info">
                        <div className="footer-section-header">Contact Information</div>
                        <div className="contact-info-details">
                            <div className="contact-info-detail"><img className="contact-info-icon" src={Constants.ICON_PHONE_SVG} alt="phone-icon"></img>{Constants.PHONE_NUMBER}</div>
                            <div className="contact-info-detail"><img className="contact-info-icon" src={Constants.ICON_EMAIL} alt="email-icon"></img>{Constants.EMAIL_ADDRESS}</div>
                            <div className="contact-info-detail"><img className="contact-info-icon" src={Constants.ICON_LOCATION} alt="location-icon"></img>{Constants.ADDRESS}</div>
                            <div className="contact-info-detail"><img className="contact-info-icon" src={Constants.ICON_HOURS} alt="hours-icon"></img>{Constants.HOURS}</div>
                        </div>
                    </div>
                    
                    <div className="footer-links">
                        <div className="footer-section-header">Links</div>
                        <div className="contact-info-detail hover-link" onClick={navigateToJobApplication}>Job Appliction</div>
                        <div className="contact-info-detail hover-link" onClick={navigateToContactUsPage}>Contact Us</div>
                    </div>
                    
                    <div className="footer-socials">
                        <div className="footer-section-header">Socials</div>
                        <img className="footer-social-icon" src={Constants.ICON_GOOGLE} alt="logo-icon"></img>
                        <img className="footer-social-icon" src={Constants.ICON_TIKTOK} alt="logo-icon"></img>
                        <img className="footer-social-icon" src={Constants.ICON_INSTAGRAM} alt="logo-icon"></img>
                    </div>
                </div>
            
        </div>
        );
    }
        
    return getFullFooter();
        
        // return (<>
        //     <div className="footer">
        //         <div className="footer-logo">
        //             <div className="footer-logo-container">
        //                 <img className="footer-logo-icon" src={LogoIcon} alt="logo-icon"></img>
        //                 <div className="footer-logo-name">Brackset</div>
        //             </div>
        //             <div className="footer-logo-tagline">The worldwide leader in bracket generation</div>

        //         </div>
        //         <div className="footer-links">
        //             <div className="footer-socials">
        //                 <img className="footer-social-icon" src={TwitterLogo} alt="logo-icon"></img>
        //                 <img className="footer-social-icon" src={InstagramLogo} alt="logo-icon"></img>

        //             </div>
        //             <div className="footer-contact-us">
        //                 <div className="contact-us-prompt">Questions? Feedback? Please reach out and let us know</div> 
        //                 <button className="contact-us-button" onClick={navigateToContactUsPage}>Contact Us</button>
        //             </div>
        //         </div>
        //     </div>
        // </>)
    
}

export default Footer;
