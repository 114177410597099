//polls=['abc','def']; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure
class CookieManager {
    
    setValue(key, value){
        document.cookie = key + '=' + encodeURIComponent(value);
    }

    setValueWithExpiration(key, value, expiration){
        var now = new Date();
        now.setTime(now.getTime() + expiration * 3600 * 1000);
        document.cookie = key + '=' + encodeURIComponent(value) + ';expires=' + now.toUTCString() + ';path=/';
    }

    addItemToList(key, value){
        let cookie = document.cookie.split('; ').find(cookieItem => {
            return cookieItem.startsWith(key);
        });

        let jsonCookieValue = [];
        if(cookie){
            jsonCookieValue = JSON.parse(cookie.split('=')[1]);
        }

        jsonCookieValue.add(value);
        document.cookie = key + '=' + encodeURIComponent(JSON.stringify(jsonCookieValue));
    }

    getValue(key){

        let cookie = document.cookie.split('; ').find(cookieItem => {
            return cookieItem.startsWith(key);
        });

        if(cookie){
            return decodeURIComponent(cookie.split('=')[1]);
        }

        return null;
    }

    // getAllRecentPolls(){

    //     let cookies = document.cookie.split('; ').filter(cookieItem => {
    //         return cookieItem.startsWith('recent-poll');
    //     });

    //     let recentPollIds = [];
    //     cookies.forEach(recentPoll => {
    //         recentPollIds.push(recentPoll.split("=")[0].replace(Constants.RECENT_POLL_COOKIE_PREFIX, ''));
    //     })

    //     return recentPollIds;
    // }

    hasCookie(key){

        let cookie = document.cookie.split('; ').find(cookieItem => {
            return cookieItem.startsWith(key);
        });

        if(cookie){
            return true;
        }

        return false
    }

    clearValue(key){
        document.cookie = key + "=''";

    }

    clearAllValues(){
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + "=; path=/; expires=" + new Date(0).toUTCString();;
        }
    }

}

export default CookieManager;


//TO CLEAR ALL COOKIES MANUALLY

// const deleteAllCookies = () => {
//     const cookies = document.cookie.split(";");
//     for (const cookie of cookies) {
//       const eqPos = cookie.indexOf("=");
//       const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//       document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
//     }
//   }
//   deleteAllCookies()