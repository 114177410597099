import React, { useState } from "react";
import "./OurTeam.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const OurTeam = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_WINDOW_WASHING_3} offsetY="100px" isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Our Team
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                        <div className="standalone-text">
                            <div className="team-info">
                                <div className="team-info-title">
                                    Rich Matonis
                                </div>
                                <div className="team-info-position">
                                    Owner
                                </div>
                            </div>
                            <div className="team-info">
                                <div className="team-info-title">
                                    Ken Matonis
                                </div>
                                <div className="team-info-position">
                                    Owner
                                </div>
                            </div>
                            <div className="team-info">
                                <div className="team-info-title">
                                    Richie Matonis
                                </div>
                                <div className="team-info-position">
                                    General Manager
                                </div>
                                <div className="team-info-phone">
                                    216-633-2334
                                </div>
                                <div className="team-info-email">
                                    richiem@residentialresources.com
                                </div>
                            </div>
                            <div className="team-info">
                                <div className="team-info-title">
                                    Ryan Short
                                </div>
                                <div className="team-info-position">
                                    Field Specialist
                                </div>
                                <div className="team-info-phone">
                                    216-644-9382
                                </div>
                                <div className="team-info-email">
                                    ryans@residentialresources.com
                                </div>
                            </div>
                            <div className="team-info">
                                <div className="team-info-title">
                                    James Olsen
                                </div>
                                <div className="team-info-position">
                                    Field Specialist
                                </div>
                                <div className="team-info-phone">
                                    216-217-3689
                                </div>
                                <div className="team-info-email">
                                    jameso@residentialresources.com
                                </div>
                            </div>
                            <div className="team-info">
                                <div className="team-info-title">
                                    Grace Matonis
                                </div>
                                <div className="team-info-position">
                                    Office Manager
                                </div>
                                <div className="team-info-phone">
                                    216-644-7035
                                </div>
                                <div className="team-info-email">
                                    office@residentialresources.com
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default OurTeam;
