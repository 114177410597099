import React, { useState } from "react";
import "./Services.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";
import { navigateToPage } from "utilities/js/NavigationHelper";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const Services = props => {

    const navigate = useNavigate();

    function navigateToSubPage(url) {
        navigateToPage({url: url});
    }

    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_WINDOW_WASHING_1} offsetY="50px" isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Services
                            </div>
                            <div className="full-image-text">
                                What we offer
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                        <div className="standalone-text">
                            <div className="header-text section-link" onClick={() => {navigateToSubPage('/services/window-cleaning')}}>Window Cleaning</div>
                            <ul>
                                <li>Windows - Wash every window and wipe down every frame, inside and out</li>
                                <li>Screens - Remove and wash every screen</li>
                                <li>Window Tracks - Vacuum out and wipe down each window track</li>
                            </ul>
                            <div className="header-text section-link" onClick={() => {navigateToSubPage('/services/pressure-washing')}}>Pressure Washing</div>
                            <ul>
                                <li>Exterior Facades</li>
                                <li>Driveways</li>
                                <li>Sidewalks</li>
                                <li>Roofs</li>
                            </ul>
                            <div className="header-text section-link" onClick={() => {navigateToSubPage('/services/high-dusting')}}>High Dusting</div>
                            <ul>
                                <li>Chandeliers</li>
                                <li>Mantles</li>
                            </ul>
                            <div className="header-text section-link" onClick={() => {navigateToSubPage('/services/carpet-cleaning')}}>Carpet Cleaning</div>
                            <ul>
                                <li>Carpet extraction clean</li>
                                <li>Rooms, runners, rugs, & furniture</li>
                            </ul>
                            <div className="header-text section-link" onClick={() => {navigateToSubPage('/services/gutter-cleaning')}}>Gutter Cleaning</div>
                            <ul>
                                <li>Leaf and soot removal</li>
                            </ul>
                            <div className="header-text section-link" onClick={() => {navigateToSubPage('/services/snow-plowing')}}>Snow Plowing</div>
                            <ul>
                                <li>Driveways and sidewalks</li>
                            </ul>
                            <div className="header-text section-link" onClick={() => {navigateToSubPage('/services/decor-install')}}>Christmas Light & Decor Installation</div>
                            <ul>
                                <li>Choose our lights or yours</li>
                                <li>Choose your color</li>
                                <li>Custom cut to fit for each house</li>
                                <li>Option to store at our warehouse</li>
                            </ul>
                        </div>
                        

                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default Services;
