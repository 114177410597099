import React from "react";
import 'components/reusable/InputBox.css';

class InputBox extends React.Component {

    //props include:
    // id
    // name
    // value
    // type
    // class
    // uniqueKey
    // placeholder
    // rows
    // cols
    // strict - decides if input box allows invalid input
    // validChecker - method to decide what input is allowed
    // valueChangedCallback - method called after new value is set

    constructor(props){
        super(props);
        this.state = {
            value: this.props.value,
            type: this.props.type ? this.props.type : 'text',
            class: this.props.class ? this.props.class : '',
            invalidInput: false
        };
        this.valueChangedCallback = this.valueChangedCallback.bind(this);
    }


    componentDidMount(){
        this.isInputValid(this.state.value);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.value !== this.props.value){
            this.setState({value: this.props.value});
        }if(prevProps.class !== this.props.class){
            this.setState({'class': this.props.class})
        }
    }

    get inputBoxClass(){
        let inputClass = this.state.class;
        if(this.state.invalidInput){
            inputClass += ' invalid-input';
        }
        return inputClass;
    }

    get inputBoxMarkup(){

        if(this.state.type === 'checkbox'){
            return (<input className={this.inputBoxClass} key={this.props.uniqueKey} type={this.state.type} name={this.props.name} data-name={this.props.name} id={this.props.id} placeholder={this.props.placeholder} checked={this.state.value} autoComplete="off" onChange={this.valueChangedCallback} disabled={this.props.disabled}></input>)
        }else if(this.state.type === 'textarea'){
            return <textarea className={this.inputBoxClass} key={this.props.uniqueKey} name={this.props.name} data-name={this.props.name} id={this.props.id} placeholder={this.props.placeholder} value={this.state.value} autoComplete="off" onChange={this.valueChangedCallback} rows={this.props.rows} cols={this.props.cols}></textarea>
        }else if(this.state.type === 'search'){        
            return (<input className={this.inputBoxClass} key={this.props.uniqueKey} type={this.state.type} name={this.props.name} data-name={this.props.name} id={this.props.id} placeholder={this.props.placeholder} value={this.state.value} autoComplete="off" onChange={this.valueChangedCallback}></input>)
        }else{        
            return (<input className={this.inputBoxClass} key={this.props.uniqueKey} type={this.state.type} name={this.props.name} data-name={this.props.name} id={this.props.id} placeholder={this.props.placeholder} value={this.state.value} autoComplete="off" onChange={this.valueChangedCallback}></input>)
        }
    }

    get warningMessageDisplay(){
        if(this.state.invalidInput && this.props.warningMessage){
            return (<div className="input-warning">{this.props.warningMessage}</div>)
        }
    }
    
    render(){
        return (
            <>
                {this.inputBoxMarkup}
                {this.warningMessageDisplay}
            </>
        );
    }

    isInputValid(value){
        let isValid = true;
        if(this.props.validChecker){
            if(!this.props.validChecker(value)){
                this.setState({invalidInput: true});
                isValid = false;
            }
        }

        return isValid;
    }

    
    // isInputNumeric(value) {
    //     let isNumeric = false;
    //     if (!value || value.length == 0) { 
    //         isNumeric = true;
    //     } else if (/[0-9.]/.test(value) ) { 
    //         isNumeric = true; 
    //     }
    //     return isNumeric;
    // }

    
    // disallowNonNumericInput(evt) {
    //     if (evt.ctrlKey) { return; }
    //     if (evt.key.length > 1) { return; }
    //     if (/[0-9.]/.test(evt.key)) { return; }
    //     evt.preventDefault();
    // }

    // formatToPhone (evt){
    //     const digits = evt.target.value.replace(/\D/g,'').substring(0,10);
    //     const areaCode = digits.substring(0,3);
    //     const prefix = digits.substring(3,6);
    //     const suffix = digits.substring(6,10);

    //     if(digits.length > 6) {evt.target.value = `(${areaCode}) ${prefix} - ${suffix}`;}
    //     else if(digits.length > 3) {evt.target.value = `(${areaCode}) ${prefix}`;}
    //     else if(digits.length > 0) {evt.target.value = `(${areaCode}`;}
    // };

    valueChangedCallback(event){
        let isValid = this.isInputValid(event.target.value);
        let newValue = '';
        if(isValid){
            this.setState({invalidInput: false});
        }

        if(isValid || !this.props.strict){
            // if(this.state.type === 'tel'){
            //     this.disallowNonNumericInput(event);
            //     this.formatToPhone(event);
            //     newValue = event.target.value;
            //     this.setState({value: event.target.value});

            // }else 
            if(this.state.type === 'checkbox'){
                newValue = event.target.checked;
                this.setState({value: event.target.checked});
            }else{
                newValue = event.target.value;
                this.setState({value: event.target.value});
            }
            if(this.props.valueChangedCallback){
                this.props.valueChangedCallback(event, newValue);
            }
        }else{
            event.target.value = this.state.value;
        }
        
    }
}

export default InputBox;