import React, { useState } from "react";
// import "./SnowPlowing.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const SnowPlowing = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_SNOWPLOW_1} offsetY="100px"  isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Snow Plowing
                            </div>
                            <div className="full-image-text">
                                What we offer
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                    <div className="standalone-text no-padding-top">
                            <p className="italics-text">
                            Ohio's premier residential snow plowing professionals. 
                            </p>
                            Let our staff clean your driveway so you don't have to.  We will tailor our frequencies and services to your needs so you can safely access and exit your home. Below are a few reasons why snow plowing is essential to residential maintenance:

                            <p>
                            <b>1. Safety:</b> Removes snow and ice from roads, driveways, and sidewalks to prevent slips, falls, and car accidents.
                            </p>

                            <p>
                            <b>2. Accessibility:</b> Keeps roads and pathways clear so people can get to work, school, and essential services.
                            </p>

                            <p>
                            <b>3. Protects Infrastructure:</b> Prevents damage to roads and pavement by reducing freeze-thaw cycles.
                            </p>
                            Rest assured, when you select Residential Resources as your service provider, you are choosing a motivated, considerate and professionally trained staff that is 100% committed to meeting and exceeding your expectations.  Our growth is linked to your satisfaction.  Call today to schedule your free estimate!    

                        </div>

                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default SnowPlowing;
