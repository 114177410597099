import React, { useState } from "react";
// import "./PressureWashing.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const PressureWashing = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_PRESSURE_WASHING_1} offsetY="-260px" isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Pressure Washing
                            </div>
                            <div className="full-image-text">
                                What we offer
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                        <div className="standalone-text no-padding-top">
                            <p className="italics-text">
                                Ohio's premier residential pressure washing professionals. 
                            </p>
                            No matter how clean your home is, its first impression is always from the outside.  Increase your homes curb appeal by letting Residential Resources of Ohio pressure wash your home's exterior.  From cleaning your siding to cleaning and sealing your sidewalks and driveway, we have the knowledge and equipment to quickly get the job done!  We also provide an assessment of your home's exterior health, reporting all failing grout and caulk, along with other potential issues. Below are a few reasons why pressure washing is essential to residential maintenance:

                            <p>
                            <b>1. Enhance Curb Appeal:</b> Over time, dirt, grime, mold, and algae can build up on surfaces like siding, driveways, patios, and fences. Pressure washing removes these stains, instantly improving the appearance of your home and boosting its curb appeal.
                            </p>

                            <p>
                            <b>2. Prevent Damage:</b> Dirt, mold, and mildew can degrade surfaces if left untreated. For example, mold can cause wood to rot, and algae can make surfaces slippery. Pressure washing helps prevent long-term damage and keeps surfaces in good condition.
                            </p>

                            <p>
                            <b>3. Increase Property Value:</b> Regular maintenance, including pressure washing, helps preserve the value of your home. A clean exterior is more attractive to potential buyers and can increase your property's resale value.
                            </p>
                            Rest assured, when you select Residential Resources as your service provider, you are choosing a motivated, considerate and professionally trained staff that is 100% committed to meeting and exceeding your expectations.  Our growth is linked to your satisfaction.  Call today to schedule your free estimate!   

                        </div>
                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default PressureWashing;
