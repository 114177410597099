//Wrapper for Class Components
//Probably wouldn't need this separate component if I was using functional components
import { useNavigate } from "react-router-dom";

export const NavigatorWrapper = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();
        
        return (
        <Component
            navigate={navigate}
            {...props}
            />
        );
    }
    
    return Wrapper;
}