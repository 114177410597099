

export function isValidString(input, options){
    if(!input || typeof input != "string"){
        return false;
    }
    if(options?.length){
        if(input.length < options.length){
            return false;
        }
    }

    return true;
}


export function isValidEmail(input){
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(input);
}

export function isValidUsername(input){
    //Usernames must be at least 6 characters and contain no special characterss
    let regex = /^([a-zA-Z0-9]){6,}$/;
    return regex.test(input) && isValidString(input, {length: 6});
}

export function isValidPhoneNumber(input) {
    // Regex pattern for common phone number formats
    const regex = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
    return regex.test(input);
}
// export function isValidPhoneNumber(input){
//     let regex = /^[\+]?[0-9]{0,3}\W?+[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
//     return regex.test(input);
// }

export function isValidPassword(input){    
    //Must have one lowercase letter, capital letter, one number, and be at least 10 characters long
    let regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})/;
    return regex.test(input);
}

//Just restrict this on publish
export function isValidContestantInput(input){
    if(!isValidString(input)){
        return false;
    }

    let newLineOccurrences = input.match(new RegExp("\n", "g"));
    if(newLineOccurrences && (newLineOccurrences.length >= 64 || newLineOccurrences.length < 1) || !newLineOccurrences){
        return false;
    }else{
        return true;
    }
}