import React from "react";
import 'components/reusable/FullPagePanel.css';
import Constants from "utilities/js/Constants";

const cancelIcon = Constants.ICON_CANCEL;
const logoIcon = Constants.ICON_LOGO;

class FullPagePanel extends React.Component {

    constructor(props){
        super(props);
        this.state = { };
        this.closePanel = this.closePanel.bind(this);

    }

    closePanel(){
        this.props.closePanel();
    }

    render(){
        let panelBackgroundClass = 'Panel full-page-panel-background';

        if(!this.props.shouldDisplay){
            panelBackgroundClass += ' hide';
        }

        // if(this.props.shouldDisplay){
            return (
                <div className={panelBackgroundClass}> 
                    <div className="full-page-panel"> 
    
                        <div className="full-page-panel-header">
                        <div className="panel-logo-container"></div>
                            <div className="close-button-container" onClick={this.closePanel}>x</div>
                        </div>
                        <div className="full-page-panel-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            );
        // }else{
        //     return;
        // }
    }
}

export default FullPagePanel;
