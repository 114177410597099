import React, { useState } from "react";
// import "./CarpetCleaning.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const CarpetCleaning = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_CARPET} isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Carpet Cleaning
                            </div>
                            <div className="full-image-text">
                                What we offer
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                    <div className="standalone-text no-padding-top">
                            <p className="italics-text">
                            Ohio's premier residential carpet cleaning professionals. 
                            </p>
                            The Residential Resources of Ohio staff is trained in current machine and chemical usage to best rid your home carpeting of unwanted stains and smells.  We offer rotary scrubbing, along with truck mounted or portable unit extraction to keep your home clean. Below are a few reasons why carpet cleaning is essential to residential maintenance.

                            <p>
                            <b>1. Improve Air Quality:</b> Carpets trap dust, dirt, allergens, and bacteria, which can affect the air quality in your home. When carpets are cleaned, these contaminants are removed, leading to better indoor air quality, which is especially important for those with allergies or asthma.
                            </p>

                            <p>
                            <b>2. Extend Carpet Life:</b> Dirt, debris, and spills can cause wear and tear on carpet fibers over time. Regular professional cleaning helps remove built-up grime, preventing fiber damage and keeping your carpets looking newer for longer. This can ultimately save you money by extending the life of your carpets and delaying the need for replacement.
                            </p>

                            <p>
                            <b>3. Remove Stubborn Stains and Odors:</b> Over time, spills from food, drinks, or other substances can lead to stains and odors that are hard to remove with regular vacuuming. Professional carpet cleaning uses advanced techniques and equipment to target and lift stubborn stains, restoring your carpet's appearance and smell.
                            </p>
                            Rest assured, when you select Residential Resources as your service provider, you are choosing a motivated, considerate and professionally trained staff that is 100% committed to meeting and exceeding your expectations.  Our growth is linked to your satisfaction.  Call today to schedule your free estimate!     

                        </div>
                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default CarpetCleaning;
