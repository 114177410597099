import "./FullPageImage.css";
import { isMobile } from "utilities/js/DeviceLibrary";

const FullPageImage = props => {

    function getImageClass(){
        let imageClass = 'full-page-image';
        if(props.isParallax){
            imageClass += ' parallax';
        }
        return imageClass;
    } 

    function getImageStyleObject(){
        let imageStyleValue = 'url(' + props.image + ')';
        let styleObj = {backgroundImage: imageStyleValue};
        if(!isMobile() && props.offsetY){
            styleObj.backgroundPosition = '0 ' + props.offsetY;
        }
        return styleObj;
    }

    return (
        <div className={getImageClass()} style={getImageStyleObject()}>
            {props.children}
        </div>
    )
    
}

export default FullPageImage;
