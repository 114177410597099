import "./HeaderButton.css";
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "utilities/js/NavigationHelper";

const HeaderButton = props => {

    const navigate = useNavigate();

    function handleClick() {
        navigateToPage({url: props.url});
    }

    return (
        <div className={"header-button " + props.variant} onClick={handleClick}>
            {props.label}
        </div>
    )
    
}

export default HeaderButton;
