const Constants = {
    SITE_NAME: 'Residential Resources of Ohio',
    MOCK_STORAGE: false,
    MOCK_API: false, 
    // MOCK_STORAGE: true,
    // MOCK_API: true, 
    // SERVER_URL: "http://localhost:3001",
    SERVER_URL: "https://eqela3ccd6wgxh7y6sb4u4znpy0hnfpq.lambda-url.us-east-2.on.aws",
    // SERVER_URL: 'https://o18lk61ry4.execute-api.us-east-2.amazonaws.com',
    //micool-dev-lambda
    // SERVER_URL: 'https://zwem16fmw1.execute-api.us-east-2.amazonaws.com',
    // SERVER_URL: 'https://api.brackset.com',

    // HOST_URL: "http://localhost:3000",
    HOST_URL: "https://residentialresources.com",
    // HOST_URL: "http://bracket-generator.s3-website.us-east-2.amazonaws.com",
    // HOST_URL: "http://micool-dev-bucket.s3-website.us-east-2.amazonaws.com",

    COOKIE_USER_KEY: 'user',
    COOKIE_USERNAME_KEY: 'username',
    COOKIE_USER_EMAIL_KEY: 'email',
    COOKIE_USER_ID: 'userid',
    COOKIE_HOURS_EXPIRATION: 5,

    SCREEN_HOME: "Home",
    SCREEN_USER_PROFILE: "User Profile",
    SCREEN_POLL_SUMMARY: "Poll Summary",
    POLL_DISPLAY_ACTIVE: "Open Poll",
    SCREEN_OPEN_POLL: "Open Poll",
    SCREEN_POLL_RESULTS: "Poll Complete",
    RECENT_POLL_COOKIE_PREFIX: "recent-poll-",
    LOCAL_SUBMISSION_EXPIRATION: 24 * 14, //two weeks 

    ERROR_TYPE_BRACKET_ERROR: 'bracketError',

    SCREEN_PARAMETER_NAME: 'screen',
    SCREEN_NAME_HOME: 'Home',
    SCREEN_NAME_COMMUNITY: 'Community',
    SCREEN_NAME_PERSONAL: 'Personal',
    HOME_SCREEN_OPTIONS: ['Home', 'Community', 'Personal'],

    FOLLOW_MESSAGE_NO_FOLLOWERS: 'You are not have any followers currently',
    FOLLOW_MESSAGE_NO_FOLLOWING: 'You are not following any other users',


    BRACKET_LIST_FEED_OPTION_FOLLOWING: 'following',

    ICON_LOADING: '/images/loading.gif',
    ICON_RESET: '/images/reset-icon.png',
    ICON_EDIT: '/images/edit-icon.svg',
    ICON_VIEW_ONLY: '/images/view-only-icon.png',
    ICON_PUBLISH: '/images/publish-icon.png',
    ICON_SETTINGS: '/images/settingsIcon.png',
    ICON_EMPTY_INBOX: '/images/empty-inbox-icon.png',
    ICON_INBOX: '/images/inbox-icon.png',
    ICON_ACCOUNT: '/images/account-icon.svg',
    ICON_NEW_TAB: '/images/open-new-tab-icon.png',
    ICON_LOGO: '/images/logo.png',
    // ICON_INSTAGRAM: '/images/instagram-logo.png',
    ICON_TWITTER: '/images/twitter-logo.png',
    ICON_INSTAGRAM_BLUE: '/images/instagram-logo-blue.png',
    ICON_TWITTER_BLUE: '/images/twitter-logo-blue.png',
    ICON_RIGHT_ARROW: '/images/right-arrow.png',
    ICON_CANCEL: '/images/cancel-icon.png',
    ICON_DELETE: '/images/delete.svg',
    ICON_HOME: '/images/home-icon-white.png',
    ICON_COMMUNITY: '/images/community-icon-white.png',
    ICON_PERSONAL: '/images/personal-icon-white.png',
    ICON_PERSONAL_NOTIFICATION: '/images/personal-icon-notification.png',
    ICON_DOWN_ARROW: '/images/down-arrow-white.png',
    ICON_COPY: '/images/copy-link-icon.png',
    ICON_LOGO_THEME: '/images/logo.png',
    ICON_CHECK: '/images/check.svg',
    ICON_ERROR: '/images/error.svg',
    ICON_IOS_SHARE: "/images/ios-chrome-more-button.svg",
    ICON_IOS_ADD_TO_HOMESCREEN: "/images/ios-safari-add-to-home-screen-button.svg",
    ICON_IOS_APP_LOGO: "/images/brackset-app-logo-192.png",

    ICON_PHONE: '/images/phone.png',
    ICON_PHONE_SVG: '/images/phone.svg',
    ICON_EMAIL: '/images/email.svg',
    ICON_LOCATION: '/images/pin.svg',
    ICON_HOURS: '/images/time.svg',

    ICON_GOOGLE: '/images/google.svg',
    ICON_TIKTOK: '/images/tiktok.svg',
    ICON_INSTAGRAM: '/images/instagram.svg',

    ICON_SV_LOGO: '/images/logo.svg',
    ICON_LOGO_WHITE: '/images/logo-white.png',

    
    IMAGE_GRACE_HEADSHOT: '/images/gmat-solo.jpg',
    IMAGE_THOR_HEADSHOT: '/images/thor.jpg',
    IMAGE_CARPET_CLEANING: '/images/stockimage-4.jpg',
    IMAGE_WINDOW_WASHING_1: '/images/stockimage-1.jpg',
    IMAGE_WINDOW_WASHING_2: '/images/stockimage-2.jpg',
    IMAGE_POWER_WASHING: '/images/stockimage-3.jpg',
    IMAGE_RESTIDENTIAL_RESOURCES: '/images/stockimage-7.jpg',
    IMAGE_AREA_OF_SERVICE: '/images/service-area-3.png',

    IMAGE_OFFICIAL_WINDOW_WASHING_1: '/images/pictures/windowcleaning.jpg',
    IMAGE_OFFICIAL_WINDOW_WASHING_2: '/images/pictures/windowcleaning-2.jpg',
    IMAGE_OFFICIAL_WINDOW_WASHING_3: '/images/pictures/windowcleaning-3.jpg',
    IMAGE_OFFICIAL_CARPET: '/images/pictures/carpetcleaning.jpg',
    IMAGE_OFFICIAL_CHRISTMAS_LIGHTS_1: '/images/pictures/christmaslights.jpg',
    IMAGE_OFFICIAL_CHRISTMAS_LIGHTS_2: '/images/pictures/christmaslights-2.jpg',
    IMAGE_OFFICIAL_GUTTER: '/images/pictures/guttercleaning.jpg',
    IMAGE_OFFICIAL_HIGH_DUSTING_1: '/images/pictures/highdusting.jpg',
    IMAGE_OFFICIAL_HIGH_DUSTING_2: '/images/pictures/highdusting-2.jpg',
    IMAGE_OFFICIAL_PRESSURE_WASHING_1: '/images/pictures/pressurewashing.jpg',
    IMAGE_OFFICIAL_PRESSURE_WASHING_2: '/images/pictures/pressurewashing-2.jpg',
    IMAGE_OFFICIAL_SNOWBLOWER: '/images/pictures/snowblower.jpg',
    IMAGE_OFFICIAL_SNOWPLOW_1: '/images/pictures/snowplow.jpg',
    IMAGE_OFFICIAL_SNOWPLOW_2: '/images/pictures/snowplow-2.jpg',

    LOCAL_STORAGE_INSTALL_NOTIFICATION: 'install-notification',

    PHONE_NUMBER: '216-785-9311',
    ADDRESS: '2140 Hamilton Ave. Cleveland, OH 44114',
    HOURS: '7AM - 7PM',
    EMAIL_ADDRESS: 'office@residentialresources.com'
}

export default Constants;