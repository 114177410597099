import React, { useState } from "react";
import "./ContactUs.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";

import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";
import InputBox from "components/reusable/InputBox";
import { isValidEmail, isValidPhoneNumber } from "utilities/js/callouts/Sanitizer";
import { contactUs } from "utilities/js/callouts/CalloutFactory";

const LogoIcon = Constants.ICON_LOGO_THEME;

const ContactUs = props => {
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("There was a problem sending your feedback");
    const [inputError, setInputError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);



    function areInputsValid(){
        let errorDisplay;
        if(!email || !phone || !firstName || !lastName || !message){
            errorDisplay = 'You must populate all fields';
        }else if(message.length < 20 || message.length > 1000){
            errorDisplay = 'Your message must be a minimum of 20 characters and maximum of 1000';
        }else if(!isValidEmail(email)){
            errorDisplay = 'You must input a valid email address';
        }else if(!isValidPhoneNumber(phone)){
            errorDisplay = 'You must input a valid phone';
        }
        if(errorDisplay){
            setInputError(errorDisplay);
            // this.registerInputContainerRef.current.scrollTop = 0;
            return false;
        }

        return true;
    }
    
    function submitFeedback(){
        if(areInputsValid()){
            setIsLoading(true);
            contactUs({email: email, firstName: firstName, lastName: lastName, phone: phone, message: message}).then(response => {
                setIsError(false)
                setSubmitSuccess(true);
            }).catch(error => {
                if(error.code){
                    setErrorMessage(error.message);
                    setIsError(true);
                }else{
                    setIsError(true);
                }
            }).finally(() => {
                setIsLoading(true);
            });
        }
    }

    function errorStringClass(){
        if(inputError){
            return 'error-messages'
        }else{
            return 'hidden';
        }
    }

    function getSuccessScreen(){
        return (
            <div className="simple-form-result-container">
                <div className="simple-form-header">Thank you!</div>
                <div className="simple-form-success-result">Your questions and feedback are greatly appreciated!  Click <a href="/">here</a> to navigate to the home page</div>
            </div>
        )
    }

    function getErrorScreen(){
        return (
            <div className="simple-form-result-container">
                <div className="simple-form-header">Something went wrong...</div>
                <div className="simple-form-error-result">
                    {errorMessage}
                    <div className="simple-form-redirect">Click <a href="/">here</a> to navigate to the home page</div>
                </div>
            </div>
        )
    }

    function getSubmitButton(){
        if(isLoading){
            return (<button label='submit-button' className='submit-button disabled' disabled><img className="loading-button" src={Constants.ICON_LOADING}></img> Submitting</button>);
        }else{
            return (<button label='submit-button' className='submit-button' onClick={submitFeedback}>Submit</button>);
        }
    }

    function displayForm(){
        if(submitSuccess){
            return getSuccessScreen();
        }else if(isError){
            return getErrorScreen();
        }else{
            return (
                <div className="contact-us-form">
                    
                    <div className="form-header">Leave us a message</div>
                    <div className="input-form-row">
                        <div className="input-container">
                            <div className="simple-form-input-label">First Name</div>
                            <InputBox uniqueKey="firstName" name='firstName' placeholder='First Name' class='contact-us-input' value={firstName} valueChangedCallback={(event) => {setFirstName(event.target.value)}}></InputBox>
                        </div>
                        <div className="input-container">
                            <div className="simple-form-input-label">Last Name</div>
                            <InputBox uniqueKey="lastName" name='lastName' placeholder='Last Name' class='contact-us-input' value={lastName} valueChangedCallback={(event) => {setLastName(event.target.value)}}></InputBox>
                        </div>
                    </div>
                    
                    <div className="input-form-row">
                        <div className="input-container">
                            <div className="simple-form-input-label">Email</div>
                            <InputBox uniqueKey="email" name='email' type="email" placeholder='Email' class='contact-us-input' value={email} valueChangedCallback={(event) => {setEmail(event.target.value)}}></InputBox>
                        </div>
                        <div className="input-container">
                            <div className="simple-form-input-label">Phone</div>
                            <InputBox uniqueKey="phone" name='phone' type="tel" placeholder='Phone' class='contact-us-input' value={phone} valueChangedCallback={(event) => {setPhone(event.target.value)}}></InputBox>
                        </div>
                    </div>

                    <div className="input-form-row">
                        <div className="input-container">
                            <div className="simple-form-input-label">Message</div>
                            <InputBox uniqueKey="message" name='message' type="textarea" placeholder='Message' class='contact-us-input' rows='8' value={message} valueChangedCallback={(event) => {setMessage(event.target.value)}}></InputBox>
                        </div>
                    </div>

                    <div className={errorStringClass()}>
                        {inputError}
                    </div>


                    <div className="submit-button-container">
                        {getSubmitButton()}
                    </div>
                </div>
            )
        }
    }
        
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    {/* <FullPageImage image="https://dummyimage.com/200x200/PURPLE/GREEN" isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Contact Us
                            </div>
                        </div>
                    </FullPageImage> */}

                    <div className="contact-form-layout">
                        
                        {displayForm()}
    
                        <div className="contact-info">
                            <div className="contact-info-header">Contact Information</div>
                            <div className="contact-info-subheader">{Constants.PHONE_NUMBER}</div>
                            <div className="contact-info-details">
                                <div className="contact-info-detail"><img className="contact-info-icon" src={Constants.ICON_EMAIL} alt="email-icon"></img>{Constants.EMAIL_ADDRESS}</div>
                                <div className="contact-info-detail"><img className="contact-info-icon" src={Constants.ICON_LOCATION} alt="location-icon"></img>{Constants.ADDRESS}</div>
                                <div className="contact-info-detail"><img className="contact-info-icon" src={Constants.ICON_HOURS} alt="hours-icon"></img>{Constants.HOURS}</div>
                            </div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d191251.50036818403!2d-81.8706744926563!3d41.49767153296285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8830ef2ee3686b2d%3A0xed04cb55f7621842!2sCleveland%2C%20OH!5e0!3m2!1sen!2sus!4v1741740172624!5m2!1sen!2sus" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>
                        
                    </div>

                    {/* <div className="landing-nav-container">
                        <div className="logo-container"><img className="bracket-logo" alt="bracket-logo" src={LogoIcon} /> </div>
                        <div className="landing-page-masthead">Residential Resources</div>
                        <div className="landing-page-tagline">The worldwide leader in residential resources</div>
                    </div> */}

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default ContactUs;
