import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import LandingPage from "./pages/landing/LandingPage";
import ContactUs from "pages/contactUs/ContactUs";
import ErrorPage from "pages/error/ErrorPage";

import './App.css';
import CookieManager from "utilities/js/localstorage/CookieManager";
// import Constants from "utilities/js/Constants";
// import { useQuery } from "@tanstack/react-query";
// import { getNotifications } from "utilities/js/callouts/CalloutFactory";
// import { isLoggedIn } from "utilities/js/ActionLibrary";
import RequestQuote from "pages/requestQuote/RequestQuote";
import JobApplication from "pages/jobApplication/JobApplication";
import OurTeam from "pages/ourTeam/OurTeam";
import Services from "pages/services/Services";
import WindowCleaning from "pages/services/window-cleaning/WindowCleaning";
import SnowPlowing from "pages/services/snow-plowing/SnowPlowing";
import PressureWashing from "pages/services/pressure-washing/PressureWashing";
import HighDusting from "pages/services/high-dusting/HighDusting";
import GutterCleaning from "pages/services/gutter-cleaning/GutterCleaning";
import DecorInstall from "pages/services/decor-install/DecorInstall";
import CarpetCleaning from "pages/services/carpet-cleaning/CarpetCleaning";

const cookieManager = new CookieManager();

const App = props => {

        // const notificationResponse = useQuery({
        //     queryKey: ['unreadNotifications'],
        //     queryFn: () => getNotifications({readStatus: false}),
        //     staleTime: Infinity,
        //     enabled: isLoggedIn()
        // });

        return (
            <>
                    <Router>
                        <div className="main-container">
                            <Routes>

                                <Route exact path='/' element={<LandingPage />}></Route>
                                <Route exact path='/our-team' element={< OurTeam />}></Route>
                                <Route exact path='/services' element={< Services />}></Route>
                                <Route exact path='/services/carpet-cleaning' element={< CarpetCleaning />}></Route>
                                <Route exact path='/services/decor-install' element={< DecorInstall />}></Route>
                                <Route exact path='/services/gutter-cleaning' element={< GutterCleaning />}></Route>
                                <Route exact path='/services/high-dusting' element={< HighDusting />}></Route>
                                <Route exact path='/services/pressure-washing' element={< PressureWashing />}></Route>
                                <Route exact path='/services/snow-plowing' element={< SnowPlowing />}></Route>
                                <Route exact path='/services/window-cleaning' element={< WindowCleaning />}></Route>
                                <Route exact path='/job-application' element={< JobApplication />}></Route>
                                <Route exact path='/request-quote' element={< RequestQuote />}></Route>
                                <Route exact path='/contact' element={< ContactUs />}></Route>
                                <Route exact path='/error' element={< ErrorPage />}></Route>
                                
                            </Routes>
                        </div>
                    </Router>
            </>
        );
}

export default App;
