import React, { useState } from "react";
// import "./HighDusting.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const HighDusting = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_HIGH_DUSTING_1} isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                High Dusting
                            </div>
                            <div className="full-image-text">
                                What we offer
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                    <div className="standalone-text no-padding-top">
                            <p className="italics-text">
                                Ohio's premier residential high dusting professionals.
                            </p>
                            We all love an open foyer concept, with an impressive, two-story high chandelier illuminating our entry and stairs, except when it comes time to clean it and replace the bulbs.  It is often the single hardest feature of any home to reach.  Let us help you safely access all those hard to get to places. Below are a few reasons why high dusting is essential to residential maintenance:

                            <p>
                            <b>1. Improved Air Quality:</b> Dust and allergens accumulate in high places like ceiling vents, light fixtures, and ceiling fans. Over time, this dust can circulate through the air and affect the quality of the air you breathe. High dusting removes these allergens and helps improve indoor air quality, which can be especially beneficial for people with asthma or allergies.
                            </p>

                            <p>
                            <b>2. Prevent Fire Hazards:</b> Dust buildup on ceiling fans, light fixtures, and HVAC vents can be a fire hazard. Dust is flammable, and if it accumulates near electrical components or high-heat areas, it can pose a risk. High dusting removes these potential fire hazards, keeping your space safer.
                            </p>

                            <p>
                            <b>3. Preserve the Integrity of Surfaces:</b> Dust buildup can degrade surfaces over time. For example, ceiling fans and light fixtures can accumulate dirt that eventually wears down finishes or causes discoloration. By regularly high dusting, you can preserve the longevity and appearance of these features.
                            </p>
                            Rest assured, when you select Residential Resources as your service provider, you are choosing a motivated, considerate and professionally trained staff that is 100% committed to meeting and exceeding your expectations.  Our growth is linked to your satisfaction.  Call today to schedule your free estimate!   

                        </div>

                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default HighDusting;
