import React, { useState } from "react";
// import "./WindowCleaning.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const WindowCleaning = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_WINDOW_WASHING_2} isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Window Cleaning
                            </div>
                            <div className="full-image-text">
                                What we offer
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                        <div className="standalone-text no-padding-top">
                            <p className="italics-text">
                                Ohio's premier residential window cleaning professionals. 
                            </p>
                            Most homeowners would agree that washing windows isn't exactly an enjoyable task. Between the ladders, screens, buckets, and rags, it can get sloppy and take up your valuable time. Let Residential Resources lend a hand, because WE LOVE TO WASH WINDOWS!!! As Ohio's premier window cleaning company, we will professionally clean your windows, letting you focus on doing the things you truly enjoy! Below are a few reasons why window cleaning is essential to residential maintenance:

                            <p>
                            <b>1. Enhance Curb Appeal:</b> Whether you're selling a house or becoming the neighborhood standard, every home looks better when looking through clean glass.   
                            </p>

                            <p>
                            <b>2. Window Lifespan:</b> Regularly cleaning the dust, dirt, and debris off of your windows and tracks helps to elongate their shelf life and to operate without issue. 
                            </p>

                            <p>
                            <b>3. Maximizing Light & Energy Efficiency:</b> Layers of dirt, smoke, fingerprints and more gather on your windows over time. These can affect natural lighting, window coatings and even insulation from functioning as intended. Regular cleaning is the best way to combat these issues and maximize your home's natural light and energy efficiency.
                            </p>
                            Rest assured, when you select Residential Resources as your service provider, you are choosing a motivated, considerate and professionally trained staff that is 100% committed to meeting and exceeding your expectations.  Our growth is linked to your satisfaction.  Call today to schedule your free estimate!     

                        </div>
                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default WindowCleaning;
