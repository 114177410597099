import React, { useState } from "react";
// import "./DecorInstall.css";
import HeaderSimple from "components/reusable/HeaderSimple";
import Footer from "components/reusable/Footer";
import { navigateToNew } from "utilities/js/NavigationHelper";

import { useNavigate } from "react-router-dom";
import Constants from "utilities/js/Constants";
import FullPageImage from "components/reusable/FullPageImage";

const LogoIcon = Constants.ICON_LOGO_THEME;
const checkIcon = Constants.ICON_CHECK;

const DecorInstall = props => {

    const navigate = useNavigate();

    function openNewBracket() {
        navigateToNew({ routerNav: navigate })
    }
    
    return (
        <>
            <div className="landing-page-layout">
                
                <HeaderSimple hideSearch="true"></HeaderSimple>
                <div class="page-content-container">
                    <FullPageImage image={Constants.IMAGE_OFFICIAL_CHRISTMAS_LIGHTS_1} offsetY="50px" isParallax="true">
                        <div className="full-image-content">
                            <div className="full-image-text">
                                Christmas Lights & Decor Installation
                            </div>
                            <div className="full-image-text">
                                What we offer
                            </div>
                        </div>
                    </FullPageImage>
                    <div className="landing-nav-container">
                        
                    <div className="standalone-text no-padding-top">
                            <p className="italics-text">
                            Ohio's premier Christmas Light & Décor installers. 
                            </p>
                            Residential Resources of Ohio is excited to add holiday lighting and decoration as a service in order to bring warmth and good cheer to your Christmas or holiday season!  Our representative will sit down with you and, together, design a display for all to enjoy! Below are a few reasons to consider our Christmas Light & Décor services:

                            <p>
                            <b>1. Saves Time & Effort:</b> Professionals handle installation, takedown, and storage, so you don't have to climb ladders or untangle wires.
                            </p>

                            <p>
                            <b>2. Safety First:</b> Reduces the risk of falls, electrical hazards, and other accidents that come with DIY installations.
                            </p>

                            <p>
                            <b>3. Professional-Quality Design:</b> Experts can create a stunning, customized display that enhances your home's beauty.
                            </p>
                            Rest assured, when you select Residential Resources as your service provider, you are choosing a motivated, considerate and professionally trained staff that is 100% committed to meeting and exceeding your expectations.  Our growth is linked to your satisfaction.  Call today to schedule your free estimate!  

                        </div>


                    </div>

                </div>
                <Footer variant="true"></Footer>
            </div>
        </>
    );

}


export default DecorInstall;
